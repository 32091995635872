/* Admin Section */

.admin-wrapper {
	width: 70%;
	margin: auto;
	text-align: center;
}

.form-header {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 3rem 0;
}

.form-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	border: 1px #c7c7c7 solid;
	padding: 2rem;
}

.form-image {
	flex: 1 1 50rem;
}

.image-preview {
	width: 35rem;
}

.form-fields {
	flex: 3 1 50rem;
	text-align: left;
}

.form-fields input {
	width: 100%;
	height: 4rem;
	font-size: 2rem;
}

.form-fields textarea {
	width: 100%;
	font-size: 2rem;
}

.form-wrapper label {
	margin: 0rem 2rem;
	text-align: left;
}

.title-form,
.description-form,
.author-form,
.price-form,
.submit-form,
.featured-form {
	padding: 0.7rem;
}

.form-fields .featured-checkbox {
	width: 2rem;
	position: relative;
	top: 1.4rem;
}

.submit-form {
	width: 100%;
}
